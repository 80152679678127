import clx from 'classnames';

import Link from 'next/link';
import useFooter from '@/organisms/Footer/useFooter';
import LogoDesktopSvg from '@/svg/logo.svg';
import LegalMenu from './components/LegalMenu';
import useKeycloak from 'hooks/useKeycloak';

const Footer = () => {
  const { footerConfig, legalFooterConfig, discordConfig, retailerConfig } = useFooter();
  const { keycloakSignin } = useKeycloak('shop');

  return (
    <footer className="relative">
      <div className="relative w-full z-[2]">
        <picture className="">
          <source media="(min-width: 768px)" srcSet="/footer-left-large.png" />
          <img alt="" loading="lazy" src="/footer-left-small.png" />
        </picture>
        <picture className="absolute right-0 top-0">
          <source media="(min-width: 768px)" srcSet="/footer-right-large.png" />
          <img alt="" loading="lazy" src="/footer-right-small.png" />
        </picture>
      </div>
      <div className="bg-sand-300 relative z-[1] md:shadow-[-5px_0px_50px_0px_#BAA87EB2] [@media(min-width:2000px)]:pt-8">
        <div className="flex flex-wrap flex-col md:flex-row-reverse items-center justify-center max-w-1200 m-auto px-4 gap-8">
          {retailerConfig?.image && retailerConfig.link && (
            <button className="lg:basis-3/12 max-w-[282px]" onClick={() => keycloakSignin()} type="button">
              <img alt="" src={retailerConfig.image.url} />
            </button>
          )}
          <div className="grow flex flex-col gap-8">
            <Link className="md:mx-0 mx-auto" href="/">
              <LogoDesktopSvg height="52" width="150" />
              {/* <img alt="" className="w-40 md:w-auto" height="235" src="logos/altered.svg" width="180" /> */}
            </Link>
            <div className="text-center md:text-left flex-col md:flex-row flex gap-4 md:gap-8">
              {footerConfig.map((config) => (
                <div key={config.key}>
                  <p className="title-5 pb-3 mb-2 text-neutral-900">{config.label}</p>
                  <ul className="flex flex-col gap-2">
                    {config.items?.map((item) => (
                      <li key={item.key}>
                        {item.href && (
                          <Link className="lg-medium text-neutral-800" href={item.href}>
                            {item.acf?.icon && <i className={clx(item.acf.icon, 'fa-fw mr-2')} />}
                            {item.label}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            {discordConfig?.link && discordConfig.title && (
              <div className="text-center md:text-left flex flex-col gap-3 pb-6">
                <p className="title-3 text-neutral-900">{discordConfig.title}</p>

                <a
                  className="lg-medium flex items-center md:flex-row flex-col md:gap-4 gap-3"
                  href={discordConfig.link.url}
                >
                  <i className={clx(discordConfig.icon, 'text-4xl text-neutral-300')} />
                  {discordConfig.link.title}
                </a>
              </div>
            )}
          </div>
          <LegalMenu items={legalFooterConfig} />
        </div>
        <img alt="" className="mt-6 w-full" height="1583" loading="lazy" src="/bg/asset_footer.png" width="1440" />
      </div>
    </footer>
  );
};

export default Footer;

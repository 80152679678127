import { useLocalStorageValue } from '@react-hookz/web';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import Button, { BUTTON_VARIANTS } from '@/atoms/Button';
import IconButton from '@/atoms/IconButton';
import { DISMISS_LANGUAGE_SWITCH } from 'constants/localStorageKeys';
import useUserApi from 'hooks/UserApi/useUserApi';

const LangDetection = () => {
  const { i18n, t } = useTranslation();
  const { user, isLoading } = useUserApi();
  const [closed, setClosed] = useState(false);
  const lsValue = useLocalStorageValue<boolean>(DISMISS_LANGUAGE_SWITCH);
  const { pathname, query } = useRouter();
  const userLang = user?.language?.locale;
  if (closed || lsValue.value || isLoading || user?.language?.locale === i18n.language || !userLang) {
    return null;
  }

  return (
    <div
      className={`
    fixed bg-neutral-0 p-4 z-1 gap-2 text-center md:text-left shadow-raised 
    bottom-20 lg:bottom-0 left-0 right-0 md:left-auto md:max-w-xs
    `}
    >
      <div className="text-right">
        <IconButton className="" icon="fa-solid fa-xmark-large" onClick={() => setClosed(true)} />
      </div>
      <p className="pr-4 md:pr-0">{t('wrong-local-detection', { lng: userLang })}</p>
      <div className="flex flex-row gap-2 justify-center">
        <Button href={{ pathname, query }} locale={userLang} variant={BUTTON_VARIANTS.SECONDARY}>
          {t('yes', { lng: userLang })}
        </Button>
        <Button onClick={() => lsValue.set(true)} variant={BUTTON_VARIANTS.TERTIARY}>
          {t('dont-ask-again', { lng: userLang })}
        </Button>
      </div>
    </div>
  );
};
export default LangDetection;

import clx from 'classnames';
import { useRouter } from 'next/router';
import TabPageItem from '@/molecules/TabsPage/TabPageItem';
import { MenuItemType } from 'types/cms';

export type TabsPageProps = {
  items: MenuItemType[];
  className?: string;
};

const TabsPage = ({ items, className }: TabsPageProps) => {
  const {
    query: { slug },
  } = useRouter();

  return (
    <ul className={clx('flex flex-row md:gap-4 bg-sand-200 rounded-[20px] p-1 w-fit shadow-overflow', className)}>
      {items.map((item) => (
        <li key={item.key}>
          <TabPageItem {...item} isActive={slug ? item.href?.includes(slug?.[0]) : false} />
        </li>
      ))}
    </ul>
  );
};
export default TabsPage;

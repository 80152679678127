import clx from 'classnames';
import Link from 'next/link';
import { useSession } from 'next-auth/react';
import Button, { BUTTON_VARIANTS } from '@/atoms/Button';
import LangDetection from '@/molecules/LangDetection';
import TabsPage from '@/molecules/TabsPage';
import CartNavButton from '@/organisms/NavBar/components/CartNavButton';
import UserNavButton from '@/organisms/NavBar/components/UserNavButton';
import useNavBar from '@/organisms/NavBar/hook';
import LogoSvg from '@/svg/logo.svg';
import { SIZE } from 'types/utils';
import ScanButton from '@/atoms/ScanButton';
import { isInAppWebview } from 'utils/webview';
import { useRouter } from 'next/router';

const NavBar = () => {
  const { menus, handleGoBack, handleUserNav, handleEditorialNav, handleCartNav, showBackButton, loginItem, scanItem } =
    useNavBar();
  const session = useSession();

  const { slug } = useRouter().query;
  const isCardsPage = slug ? slug.includes('cards') : false;

  return (
    <div className="fixed z-40 left-2 right-2 bottom-2 lg:bottom-auto lg:top-4 lg:left-4 lg:right-4 max-w-1200 mx-auto lg:flex lg:gap-4 lg:items-center">
      <header
        className={clx(
          !showBackButton
            ? 'bg-sand-200/70 shadow-overflow backdrop-blur-md'
            : 'bg-invisible lg:bg-sand-200/70 lg:backdrop-blur-md',
          'lg:grow flex items-center justify-between pl-3 pr-1 py-1 lg:pl-4 rounded-3xl gap-2 lg:gap-4',
        )}
      >
        <div className={clx('flex gap-2 lg:gap-4 items-center', showBackButton && 'hidden lg:flex')}>
          <Link className="hidden lg:block" href="/">
            <LogoSvg height="40" width="116" />
          </Link>
          <Button className="h-full" icon="fa-solid fa-bars" onClick={handleEditorialNav} size={SIZE.LG} />
        </div>
        {menus.length > 0 && <TabsPage className={clx(showBackButton && 'hidden lg:flex')} items={menus} />}
      </header>
      <div className="fixed top-2 right-2 lg:static flex gap-2 lg:gap-4">
        {!!session.data?.accessToken && <CartNavButton onClick={handleCartNav} />}
        <UserNavButton loginItem={loginItem} onClick={handleUserNav} />
      </div>
      {showBackButton && (
        <Button
          className="shadow-overflow fixed top-2 left-2 lg:hidden"
          icon="fa-solid fa-chevron-left"
          onClick={handleGoBack}
          size={SIZE.LG}
          variant={BUTTON_VARIANTS.TERTIARY}
        />
      )}
      <LangDetection />
      {scanItem && isInAppWebview() && isCardsPage && (
        <ScanButton className="fixed bottom-header-mobile right-2 mb-6 bottom-mobile-header" href={scanItem.href}>
          {scanItem.label}
        </ScanButton>
      )}
    </div>
  );
};

export default NavBar;

import clx from 'classnames';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import ERROR_ROUTES from 'constants/routes';
import { MenuItemType } from 'types/cms';

const LegalMenu = ({ items }: { items: MenuItemType[] }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col md:flex-row w-full justify-between px-4 pt-4 border-t border-t-neutral-70 gap-4 max-w-7xl md:m-auto">
      <ul className="flex gap-2 justify-center">
        {items.map(({ key, href, label }, index) => (
          <li key={key}>
            <Link
              className={clx(
                'md-medium ',
                index + 1 === items.length ? 'mb-4 md:mr-auto md:mb-0' : 'md:pr-4 md:border-r-2 md:border-r-white',
              )}
              href={href ?? ERROR_ROUTES.NOT_FOUND}
              suppressHydrationWarning
            >
              {label}
            </Link>
          </li>
        ))}
      </ul>
      <p className="md-bold-caps text-center text-neutral-800 ">{t('all-rights-reserved')}</p>
    </div>
  );
};

export default LegalMenu;

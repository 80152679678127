import { useCmsData } from 'utils/cms';
import { formatMenuItems } from 'utils/cms/formatMenuItems';

const useFooter = () => {
  const {
    acf: { footerMenus },
  } = useCmsData();
  const footerConfig = formatMenuItems(footerMenus.footerClassic?.menuItems || []);
  const legalFooterConfig = formatMenuItems(footerMenus.footerLegalClassic?.menuItems || []);

  return {
    discordConfig: footerMenus.footerClassic?.discord,
    footerConfig,
    legalFooterConfig,
    retailerConfig: footerMenus.footerClassic?.retailer,
  };
};

export default useFooter;

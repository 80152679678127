import ButtonOrLink from '@/atoms/ButtonOrLink/ButtonOrLink';
import Sticker from '@/atoms/Sticker';
import clx from 'classnames';
import { useTranslation } from 'react-i18next';
import { MenuItemType } from 'types/cms';

type TabPageItemProps = MenuItemType & {
  isActive?: boolean;
};

const TabPageItem = ({ isActive, acf, label, hasPlaceholder, ...props }: TabPageItemProps) => {
  const { t } = useTranslation();

  return (
    <ButtonOrLink
      className={clx(
        'px-3 py-2 rounded-2xl flex flex-col md:flex-row md:gap-2 justify-center items-center relative',
        'sm-regular md:md-bold-caps whitespace-nowrap',
        isActive ? 'bg-primary-400 text-neutral-800' : 'text-neutral-500',
        'hover:bg-primary-400 hover:text-neutral-800',
      )}
      {...props}
      type="button"
      suppressHydrationWarning
    >
      {acf?.icon && <i className={acf.icon} />} {label}
      {hasPlaceholder && <Sticker className="absolute -bottom-0 translate-y-1/2">{t('soon')}</Sticker>}
    </ButtonOrLink>
  );
};
export default TabPageItem;

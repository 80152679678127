import { useRouter } from 'next/router';
import ERROR_ROUTES from 'constants/routes';
import { useModal } from 'context/Modal/Context';

import { MODAL_NAME } from 'context/Modal/MODAL_NAME';
import { useCmsData } from 'utils/cms';
import { formatMenuItems, formatSpecificMenuItems } from 'utils/cms/formatMenuItems';

const useNavBar = () => {
  const {
    acf: { headerMenus, parentForceUrl },
    parent,
  } = useCmsData();
  const { push } = useRouter();
  const { openModal } = useModal();

  /**  very specific rules
  if the page has a parent or a parent_force_url >> put a back button
  if the parent_force_url === # >> use the normal nav
  the parent can also have a parent_force_url 
  the parent info override current node parent_force_url
  * */
  const showBackButton =
    (parent?.url && parent.acf && parent.acf.parent_force_url !== '#') || (parentForceUrl && parentForceUrl !== '#');

  const handleGoBack = () => {
    if (parent?.acf && parent.url) {
      return push(parent.acf.parent_force_url || parent.url);
    }
    if (parentForceUrl) {
      return push(parentForceUrl);
    }

    return push(ERROR_ROUTES.NOT_FOUND);
  };

  const handleUserNav = () => {
    openModal(MODAL_NAME.USER_NAV, {});
  };

  const handleEditorialNav = () => {
    openModal(MODAL_NAME.EDITORIAL_NAV, {});
  };

  const handleCartNav = () => {
    openModal(MODAL_NAME.CART_NAV, {});
  };

  const menus = formatMenuItems(headerMenus.headerClassic?.menuItems || []);
  const { login: loginItem, scan: scanItem } = formatSpecificMenuItems(headerMenus.headerClassic?.menuItems || []);

  return {
    menus,
    showBackButton,
    handleGoBack,
    handleUserNav,
    handleEditorialNav,
    handleCartNav,
    loginItem,
    scanItem,
  };
};

export default useNavBar;

import Badge from '@/atoms/Badge';
import Button, { BUTTON_VARIANTS } from '@/atoms/Button';
import useMyCart from 'hooks/queries/marketplace/carts/useMyCart';
import { SIZE, VARIANT_COLOR_ENUM } from 'types/utils';

const CartNavButton = ({ onClick }: { onClick: () => void }) => {
  const { cart } = useMyCart();

  return cart?.totalQuantity ? (
    <Button
      className="relative"
      icon="fa-solid fa-cart-shopping"
      onClick={onClick}
      size={SIZE.LG}
      variant={BUTTON_VARIANTS.TERTIARY}
    >
      <Badge className="absolute -right-1 -bottom-1" variant={VARIANT_COLOR_ENUM.ERROR}>
        {cart.totalQuantity}
      </Badge>
    </Button>
  ) : null;
};

export default CartNavButton;

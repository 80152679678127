import Avatar from '@/atoms/Avatar';
import Button, { BUTTON_VARIANTS } from '@/atoms/Button';
import useUserApi from 'hooks/UserApi/useUserApi';
import { MenuItemType } from 'types/cms';
import { SIZE } from 'types/utils';
import useKeycloak from 'hooks/useKeycloak';

const UserNavButton = ({ onClick, loginItem }: { loginItem: MenuItemType; onClick: () => void }) => {
  const { user } = useUserApi();
  const { keycloakSignin } = useKeycloak();

  const renderLoginButton = () => (
    <Button icon={loginItem.acf?.icon} onClick={() => keycloakSignin()} size={SIZE.MD}>
      <span className="hidden lg:block">{loginItem.label}</span>
    </Button>
  );

  return user ? (
    <Button onClick={onClick} size={SIZE.SM} variant={BUTTON_VARIANTS.TERTIARY}>
      <span className="hidden lg:block">{user.nickName}</span>
      <Avatar alt="" size={SIZE.SM} src={user.avatarPath} />
    </Button>
  ) : (
    loginItem && renderLoginButton()
  );
};

export default UserNavButton;
